import { useTranslation } from 'react-i18next';
import GlobalSettings from '../GlobalSettings';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import * as SiIcons from "react-icons/si";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as FcIcons from "react-icons/fc";
import * as GoIcons from "react-icons/go";
import * as BsIcons from "react-icons/bs";
import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';

const SidebarLink = styled(Link)`
  display: flex;
  color: #f9fafb;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  /* font-size: 20px; */
  &:hover {
    background: #4d4d4d;
    border-left: 4px solid #ff9900;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 10px;
`;

const DropdownLink = styled(Link)`
  background: #444444;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  /* font-size: 18px; */
  &:hover {
    background: #4d4d4d;
    border-left: 4px solid #f9fafb;
    cursor: pointer;
  }
`;



const Nav = styled.div`
    background: #444444;
    // height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 1200px) {
        display: none; 
    }

    padding-right: 64px;
    padding-left: 64px;

    @media screen and (max-width: 1400px) {
        padding-right: 64px;
        padding-left: 64px;
    }

    @media screen and (max-width: 768px) {
        padding-right: 34px;
        padding-left: 34px;
    }

    @media screen and (max-width: 480px) {
        padding-right: 14px;
        padding-left: 14px;
    }
`;

const NavIcon = styled(Link)`
    // margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: 1200px) {
        display: none; 
    }
`;

const NavIconClose = styled(Link)`
    // margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: 1200px) {
        display: none; 
    }

    padding-right: 64px;
    padding-left: 64px;

    @media screen and (max-width: 1400px) {
        padding-right: 64px;
        padding-left: 64px;
    }

    @media screen and (max-width: 768px) {
        padding-right: 34px;
        padding-left: 34px;
    }

    @media screen and (max-width: 480px) {
        padding-right: 14px;
        padding-left: 14px;
    }
`;

const SidebarNav = styled.nav`
    background: #444444;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
    overflow-y: auto;

    width: 400px;

    @media screen and (max-width: 768px) {
        width: 350px;
    }

    @media screen and (max-width: 480px) {
        width: 70%;
    }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const LanguageButton = styled.button`
    background-color: ${GlobalSettings.mainYellowColor};
    color: ${GlobalSettings.mainWhiteColor};
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    border-color: transparent;
    /* transition: ease background-color 0.25s; */
    border-radius:50px;
    &:hover {
      color: ${GlobalSettings.mainDarkColor};
      background-color: ${GlobalSettings.mainWhiteColor};
      cursor: pointer;
    }
`;


const Sidebar = () => {

    const {t, i18n} = useTranslation('header_footer');

    /*
    function ToggleLanguage(language_code) {
        localStorage.setItem("i18nextLng", language_code);
        i18n.changeLanguage(language_code);

        if (window.location.pathname === "/en/home" || window.location.pathname === "/fr/accueil") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/accueil" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/home";
            }
        }
        else if (window.location.pathname === "/en/about-the-rdct" || window.location.pathname === "/fr/a-propos-du-rdct") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/a-propos-du-rdct" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/about-the-rdct";
            }
        }
        else if (window.location.pathname === "/en/history" || window.location.pathname === "/fr/histoire") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/histoire" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/history";
            }
        }
        else if (window.location.pathname === "/en/research-web-technologies" || window.location.pathname === "/fr/recherche-technologies-web") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/recherche-technologies-web" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/research-web-technologies";
            }
        }
        else if (window.location.pathname === "/en/research-digital-marketing" || window.location.pathname === "/fr/recherche-marketing-digitale") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/recherche-marketing-digitale" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/research-digital-marketing";
            }
        }
        else if (window.location.pathname === "/en/research-management" || window.location.pathname === "/fr/recherche-management") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/recherche-management" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/research-management";
            }
        }
        else if (window.location.pathname === "/en/internship-program" || window.location.pathname === "/fr/programme-stages") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/programme-stages" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/internship-program";
            }
        }
        else if (window.location.pathname === "/en/training-web-development-frontend" || window.location.pathname === "/fr/formation-developpement-web-frontend") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-developpement-web-frontend" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-web-development-frontend";
            }
        }
        else if (window.location.pathname === "/en/training-web-development-backend" || window.location.pathname === "/fr/formation-developpement-web-backend") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-developpement-web-backend" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-web-development-backend";
            }
        }
        else if (window.location.pathname === "/en/training-search-engine-optimization" || window.location.pathname === "/fr/formation-search-engine-optimization") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-search-engine-optimization" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-search-engine-optimization";
            }
        }
        else if (window.location.pathname === "/en/training-digital-marketing" || window.location.pathname === "/fr/formation-marketing-digitale") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-marketing-digitale" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-digital-marketing";
            }
        }
        else if (window.location.pathname === "/en/training-microsoft-office" || window.location.pathname === "/fr/formation-microsoft-office") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-microsoft-office" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-microsoft-office";
            }
        }
        else if (window.location.pathname === "/en/consulting-services" || window.location.pathname === "/fr/services-conseil") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/services-conseil" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/consulting-services";
            }
        }
        else if (window.location.pathname === "/en/support-for-visa" || window.location.pathname === "/fr/aide-pour-visa") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/aide-pour-visa" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/support-for-visa";
            }
        }
        else if (window.location.pathname === "/en/terms-of-use" || window.location.pathname === "/fr/politique-utilisation") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/politique-utilisation" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/terms-of-use";
            }
        }
        else if (window.location.pathname === "/en/privacy-statements" || window.location.pathname === "/fr/politique-donnees") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/politique-donnees" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/privacy-statements";
            }
        }
        else if (window.location.pathname === "/en/cookie-statements" || window.location.pathname === "/fr/politique-cookies") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/politique-cookies" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/cookie-statements";
            }
        }

    }*/

    function ToggleLanguage(language_code) {
        localStorage.setItem("i18nextLng", language_code);
        i18n.changeLanguage(language_code);
    }

    const SidebarData = [
        {
            title: t('home_menu'),
            path: t('home_canonical_url'),
            icon: <AiIcons.AiFillHome size='1.0em'/>
        },
        {
            title: 'RDCT',
            path: '#',
            icon: <GoIcons.GoIssueOpened size='1.0em' />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: t('about_menu.title'),
                    path: t('about_menu.canonical_url'),
                    icon: <FcIcons.FcAbout size='1.0em'/>
                },
                {
                    title: t('history_menu.title'),
                    path: t('history_menu.canonical_url'),
                    icon: <AiIcons.AiOutlineHistory size='1.0em'/>
                }
            ]
        },
        {
            title: t('research_menu'),
            path: '#',
            icon: <GiIcons.GiArchiveResearch size='1.0em'/>,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: t('web_technologies_menu.title'),
                    path: t('web_technologies_menu.canonical_url'),
                    icon: <SiIcons.SiSemanticweb size='1.0em'/>
                },
                {
                    title: t('digital_marketing_menu.title'),
                    path: t('digital_marketing_menu.canonical_url'),
                    icon: <GiIcons.GiDigitalTrace size='1.0em'/>
                },
                {
                    title: t('management_menu.title'),
                    path: t('management_menu.canonical_url'),
                    icon: <MdIcons.MdOutlineManageAccounts size='1.0em'/>
                }
            ]
        },
        {
            title: t('formations_menu'),
            path: '#',
            icon: <MdIcons.MdCastForEducation size='1.0em'/>,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: t('training_web_development_frontend_menu.title'),
                    path: t('training_web_development_frontend_menu.canonical_url'),
                    icon: <RiIcons.RiReactjsFill size='1.0em'/>
                },
                {
                    title: t('training_web_development_backend_menu.title'),
                    path: t('training_web_development_backend_menu.canonical_url'),
                    icon: <AiIcons.AiOutlineConsoleSql size='1.0em'/>
                },
                {
                    title: t('training_search_engine_optimization_menu.title'),
                    path: t('training_search_engine_optimization_menu.canonical_url'),
                    icon: <AiIcons.AiOutlineFileSearch size='1.0em'/>
                },
                {
                    title: t('training_digital_marketing_menu.title'),
                    path: t('training_digital_marketing_menu.canonical_url'),
                    icon: <GiIcons.GiDigitalTrace size='1.0em'/>
                },
                {
                    title: t('training_microsoft_office_menu.title'),
                    path: t('training_microsoft_office_menu.canonical_url'),
                    icon: <SiIcons.SiMicrosoftword size='1.0em'/>
                }
            ]
        },
        {
            title: t('internship_program_menu'),
            path: t('internship_canonical_url'),
            icon: <BsIcons.BsStack size='1.0em'/>,
        },
        {
            title: t('solution_menu'),
            path: '#',
            icon : <SiIcons.SiSololearn size='1.0em'/>,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: t('clean_agency_menu.title'),
                    path: 'https://clean.rdct.tech',
                    icon: <MdIcons.MdCleaningServices size='1.0em'/>
                },
                {
                    title: t('book_sharing_menu.title'),
                    path: 'http://booksharing.rdct.tech',
                    icon: <MdIcons.MdBookOnline size='1.0em'/>
                }
            ]
        },
        {
            title: t('consulting_services_menu.title'),
            path: t('consulting_services_menu.canonical_url'),
            icon: <RiIcons.RiServiceFill size='1.0em'/>,
        },
        {
            title: t('support_for_visa_menu.title'),
            path: t('support_for_visa_menu.canonical_url'),
            icon: <BiIcons.BiHelpCircle size='1.0em'/>,
        },
        {
            title: 'Contacts',
            path: '#',
            icon : <RiIcons.RiContactsBook2Line size='1.0em'/>,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
              {
                title: 'Google map',
                path: 'https://g.page/r/CfM7vZT4bsaSEAE?gm',
                icon: <GiIcons.GiPositionMarker size='1.0em'/>
              },
              {
                title: '(+237) 682634355',
                path: '#',
                icon: <BsIcons.BsFillTelephoneFill size='1.0em'/>
              },
              {
                title: '(+237) 694060524',
                path: '#',
                icon: <BsIcons.BsFillTelephoneFill size='1.0em'/>
              },
              {
                title: '(+237) 233392501',
                path: '#',
                icon: <BsIcons.BsFillTelephoneFill size='1.0em'/>
              },
              {
                title: 'info@rdct.tech',
                path: '#',
                icon: <MdIcons.MdOutlineMailOutline size='1.0em'/>
              }
            ]
        }
    ];


    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    function useWindowSize() {
        useEffect(() => {
            const handleResize = () => {
                if (1200 < window.innerWidth) {
                    setSidebar(false);
                }
            };
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);
    }

    useWindowSize();

    const SubMenu = ({ item }) => {
        const [subnav, setSubnav] = useState(false);

        const showSubnav = () => setSubnav(!subnav);

        return (
            <>
                <SidebarLink to={item.path} onClick={item.subNav ? showSubnav : showSidebar}>
                    <div>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </div>
                    <div>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                                ? item.iconClosed
                                : null}
                    </div>
                </SidebarLink>
                {subnav &&
                    item.subNav.map((item, index) => {
                        return (
                            <DropdownLink to={item.path} key={index} onClick={showSidebar}>
                                {item.icon}
                                <SidebarLabel>{item.title}</SidebarLabel>
                            </DropdownLink>
                        );
                    })}
            </>
        );
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <NavIcon to='#'>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </NavIcon>
                    <LanguageButton onClick={() => ToggleLanguage(t('code'))}>{t('code_name')}</LanguageButton>
                </Nav>
                <SidebarNav sidebar={sidebar} >
                    <SidebarWrap>
                        <NavIconClose to='#'>
                            <AiIcons.AiOutlineClose onClick={showSidebar} />
                        </NavIconClose>
                        {SidebarData.map((item, index) => {
                            return <SubMenu item={item} key={index} />;
                        })}
                    </SidebarWrap>
                </SidebarNav>
            </IconContext.Provider>
        </>
    );
};


const HeaderTabletSmarphone = () => {
    return (
        <Sidebar />
    );
};

export default HeaderTabletSmarphone;